import React, { useEffect } from 'react'
import { useStateValue } from 'context/PortfolioContext'
import 'scss/workname.scss'


const WorkNameBg = () => {
  const [{currProject}] = useStateValue();


  useEffect(() => {
    // to avoid undefined
    if (typeof currProject != 'undefined') {
      // customize animation class and delay
      if (currProject.name === "portfolio" || currProject.name === "Portfolio" || currProject.name === "PORTFOLIO") {
        document.getElementById('worknameTitle').innerHTML = ".this";
      }
      ShowCurrProject('slide-workname-anim', 400);
    }
  }, [currProject])

  const ShowCurrProject = (workClassAnim, timeOut) => {
    let wnTitle = document.getElementById('worknameTitle');
    wnTitle.classList.add(...[workClassAnim]);
    setTimeout(() => {
      wnTitle.classList.remove(workClassAnim);
    }, timeOut);
  }

  return (
    <div className="workname">
      <div className="workname__container">
        <span className="workname__title" id="worknameTitle">
          {currProject.name}
        </span>
      </div>
    </div>
  )
}

export default WorkNameBg
