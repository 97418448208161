import React from 'react'
import 'scss/works.scss'
import Layout from 'components/layout'
import SEO from 'components/seo'
import NextBtn from 'ui/next-btn'
import Work from 'components/works/work'
import WorkWindow from 'works/work-window'
import Scroller from 'ui/scroller';


const WorksPage = () => {
  
  return (
    <Layout pageTitle="WORKS">
      <SEO
        title="Works"
        image="/img/works-preview.png"
        description="Passionate Web Developer. Checkout some humble works which I made with the latest web technologies"
      />
      <Work />
      <Scroller />
      <NextBtn
        domClassesToHide=".header,.works__container,.next__btn,.workname"
        nextBtnTitle="Skills"
        nextPageLink="/skills" />

      <WorkWindow />
      
    </Layout>
  )
}

export default WorksPage
