import React from 'react'
import { useStateValue } from 'context/PortfolioContext'
import * as Bi from 'react-icons/bi'
import * as Icon from 'react-bootstrap-icons'
import 'scss/workwindow.scss'

const WorkWindow = () => {
  const [{currProject}] = useStateValue();

  const HideWorkWindow = () => {
    let workWind = document.getElementById('workWindow');
    workWind.classList.add(...['hide-work-window'])
    setTimeout(() => {
      workWind.classList.remove('hide-work-window');
      workWind.setAttribute('style', 'display: none;')
    }, 1000);
  }

  return (
    <div className="workw" id="workWindow">
      <div className="workw__container">
        
        <div className="workw__imgSection">
          <img width="auto" height="auto" className="workw__img" src={currProject.cover} alt={currProject.desc} />
        </div>
        <div className="workw__contentSection">
          <h2 className="workw__title">
            { currProject.name }
          </h2>
          <strong className="workw__tech">
            { currProject.tech }
          </strong>
          <p className="workw__desc">
            { currProject.desc }
          </p>
        </div>
        {/* END workw__contentSection */}

        <div className="workw__btns">
          {(currProject.url !== "#") ?
            <a className="workw__btn" href={currProject.url || '#'} target="_blank" rel="noopener noreferrer">
              <span>Open Project</span>
              <Icon.Link45deg size={20}></Icon.Link45deg>
            </a> :
            <button aria-label="soon btn" className="workw__btn" disabled>
              <span>Private</span>
              <Bi.BiLockAlt size={20}></Bi.BiLockAlt>
            </button>}
            {/* CLOSE BTN */}
            <button aria-label="hide window btn" className="workw__btn" onClick={HideWorkWindow}>
              <span>Close</span>
              <Icon.X size={20}></Icon.X>
            </button>
        </div>

      </div>
    </div>
  )
}

export default WorkWindow
