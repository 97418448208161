import React, { useEffect} from 'react'
import { useStateValue } from 'context/PortfolioContext'
// import { Link } from 'gatsby'
import { showPageWithTransition } from 'Helpers/functions'
import WorkPreview from 'works/work-preview';
import WorkNameBg from 'works/work-name-bg';
import * as Icon from 'react-bootstrap-icons'

const Work = () => {
  const [{currProject, projects}, dispatch] = useStateValue();

  useEffect(() => {
    showPageWithTransition();
  }, []);

  const SetCurrProject = (id) => {
    dispatch({type: "SET_CURR_PROJECT", id: id})
  }

  const ShowWorkWindow = () => {
    let workWind = document.getElementById('workWindow');
    // show work window
    workWind.setAttribute('style', 'display: flex');
  }

  return (
    <section className="works">
      
      <div className="works__container">
        <div className="works__list" data-speed="1">
          {projects?.map((project) => 
            <div className="works__item" key={Math.random()*100000}>
              <h1 className="works__itemTitle">
              <span className={(currProject.id === project.id) ? 'text-active' : ''} onClick={() => {SetCurrProject(project.id); ShowWorkWindow()}}>
                {project.name}
              </span>
              </h1>
              <button aria-label="set project btn" className="works__itemBtn" onClick={() => {SetCurrProject(project.id)}}>
                <Icon.ArrowRightShort className="materialize-icons">
                </Icon.ArrowRightShort>
              </button>
            </div>
          )}
        </div>

        <WorkPreview ShowWorkWindow={ShowWorkWindow} />
        
        <WorkNameBg />

      </div>
      
    </section>
  )
}

export default Work
