import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { useStateValue } from 'context/PortfolioContext'
import { TranslateUpAnim, parallaxContentWithCursor } from 'Helpers/functions'
import * as Icon from 'react-bootstrap-icons'


const WorkPreview = ({ShowWorkWindow}) => {
  const [{currProject}, dispatch] = useStateValue();

  useEffect(() => {
    dispatch({type: 'SET_CURR_PROJECT'});
    //console.log("currProject", currProject);
  }, [])

  // PARALLAX CONTENT
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('mousemove', (e) => {
        parallaxContentWithCursor(e, '.works__previewTitle', false);
      });
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('mousemove', parallaxContentWithCursor);
      }
    }
  }, []);

  const SwithProject = (actionType) => {
    setTimeout(() => {
      dispatch({type: actionType});
    }, 150); // dispatch action after text faledown
    TranslateUpAnim('workPreviewTitle');
  }
  

  return (
    <div className="works__preview" id="workPreview">
      <button aria-label="prev-btn" className="works__itemBtn" id="btnfixedLeft" onClick={() => {SwithProject('DECREMENT')}}>
        <Icon.ArrowLeft></Icon.ArrowLeft>
      </button>

      <div className="works__previewContainer">
        <Link to="#" className="works__previewTitle" id="workPreviewTitle" data-speed="1" onClick={ShowWorkWindow}>
          { currProject.name }
          <small className="works__previewTitleTech">{ currProject.tech }</small>
        </Link>
        <div className="works__previewFrame">
          <div className="works__previewMain">
          </div>
        </div>
      </div>

      <button aria-label="next-btn" className="works__itemBtn" id="btnfixedRight" onClick={() => {SwithProject('INCREMENT')}}>
        <Icon.ArrowRight></Icon.ArrowRight>
      </button>

    </div>
  )
}

export default WorkPreview